import { useContext } from "react";
import { MyContext } from "../App";
import "./allCSSfiles/nextmatch.css";
import FootballTable from "./homeTable";

const Nextmatch = () => {
  const { data } = useContext(MyContext);
  const arr = data || [];

  // Filter for "NOT STARTED" matches
  const notStartedMatches = arr.filter(
    (match) => match.status === "NOT STARTED"
  );

  // Sort by date and time
  const nextMatch = notStartedMatches.sort((a, b) => {
    const dateA = new Date(`${a.date}T${a.time}`);
    const dateB = new Date(`${b.date}T${b.time}`);
    return dateA - dateB;
  })[0];

  return (
    <div className="soccerContainer">
      <div className="nextMatch">
        {/* <h2>Next Match</h2> */}
        {nextMatch ? (
          <div className="match-container">
            <p className="venu">
              <span>{nextMatch.stadium} stadium</span>
              <span>{nextMatch.date}</span>
            </p>
            <div className="match">
              <div className="teamHome">
                <img
                  src={nextMatch.homeURL}
                  alt={nextMatch.homeName + " logo"}
                />
                <span className="homeTeam">{nextMatch.homeName}</span>
              </div>
              <p className="time">{nextMatch.time}</p>
              <div className="teamAway">
                <img
                  src={nextMatch.awayURL}
                  alt={nextMatch.awayName + " logo"}
                />
                <span className="awayTeam">{nextMatch.awayName}</span>
              </div>
            </div>
          </div>
        ) : (
          <p className="nomatch">No upcoming matches</p>
        )}
      </div>
      <FootballTable />
    </div>
  );
};

export default Nextmatch;
