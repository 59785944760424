import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../App";
import Logo from "../logopre/pre.png";
import "./allCSSfiles/fixture.css";

const Fixture = () => {
  const { data } = useContext(MyContext);
  const [selectedRound, setSelectedRound] = useState(null);
  const [allRounds, setAllRounds] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    if (data) {
      const uniqueRounds = [...new Set(data.map((item) => item.round))].sort(
        (a, b) => a - b
      );
      setAllRounds(uniqueRounds);
      const now = new Date();
      let currentRound = null;
      for (let i = uniqueRounds.length - 1; i >= 0; i--) {
        const roundMatches = data.filter(
          (item) => item.round === uniqueRounds[i]
        );
        const firstMatchDate = new Date(roundMatches[0].date);
        if (firstMatchDate <= now) {
          currentRound = uniqueRounds[i];
          break;
        }
      }
      if (currentRound) {
        setSelectedRound(currentRound);
        const initialIndex = uniqueRounds.findIndex(
          (round) => round === currentRound
        );
        setCarouselIndex(Math.max(0, initialIndex - 1));
      } else {
        setSelectedRound(uniqueRounds[0] || null);
        setCarouselIndex(0);
      }
    }
  }, [data]);

  const filterDataBySelectedRound = () => {
    if (!selectedRound) return [];
    return data.filter((item) => item.round === selectedRound && item.status === "NOT STARTED");
  };
  
  const handleRoundClick = (round) => {
    setSelectedRound(round);
  };
  const handleSlideLeft = () => {
    if (carouselIndex > 0) {
      setCarouselIndex(carouselIndex - 1);
    }
  };
  const handleSlideRight = () => {
    if (carouselIndex < allRounds.length - 3) {
      setCarouselIndex(carouselIndex + 1);
    }
  };

  const filteredData = filterDataBySelectedRound();
  const renderRoundCarousel = () => {
    const visibleRounds = allRounds.slice(carouselIndex, carouselIndex + 4);
    return (
      <div className="date-carousel-wrapper">
        <button
          className="carousel-control"
          onClick={handleSlideLeft}
          disabled={carouselIndex === 0}
        >
          &lt;
        </button>
        <div className="date-carousel">
          {visibleRounds.map((round, index) => (
            <button
              key={index}
              className={`carousel-button ${
                selectedRound === round ? "active" : ""
              }`}
              onClick={() => handleRoundClick(round)}
            >
              {`${round}`}
            </button>
          ))}
        </div>
        <button
          className="carousel-control"
          onClick={handleSlideRight}
          disabled={carouselIndex >= allRounds.length - 4}
        >
          &gt;
        </button>
      </div>
    );
  };

  const renderMatches = () => {
    if (filteredData.length === 0) {
      return (
        <div className="f-no-matches-message">
        <p >
          All this week matches are completed.
        </p>
        </div>
      );
    }

    return filteredData.map((match) => (
      <div className="fixture-card" key={match.id}>
        <div className="fixture-info">
          <span className="fixture-span">{match.homeName}</span>
          <img src={match.homeURL} alt={`${match.homeName} logo`} />
          <button className="fixture-time">{match.time}</button>
          <img src={match.awayURL} alt={`${match.awayName} logo`} />
          <span className="fixture-span">{match.awayName}</span>
        </div>
        <div className="fixture-details">
          <span>{match.stadium} stadium</span>
          <span>{match.date}</span>
        </div>
      </div>
    ));
  };

  return (
    <div className="fixture">
      <div className="prem-logo">
        <img src={Logo} alt="Premier League logo" />
      </div>
      <p className="fixture-header">ETHIOPIAN PREMIER LEAGUE</p>
      {renderRoundCarousel()}
      {renderMatches()}
    </div>
  );
};

export default Fixture;
