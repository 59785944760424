import { NavLink } from "react-router-dom";
import "./allCSSfiles/footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="social-btn">
        <NavLink
          className="social fb"
          to="https://www.facebook.com/bahirdarksc"
        >
          <i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
        </NavLink>
        <NavLink className="social instagram" to="insta">
          <i className="fa fa-instagram fa-2x" aria-hidden="true"></i>
        </NavLink>
        <NavLink className="social telegram" to="tele">
          <i className="fa fa-telegram fa-2x" aria-hidden="true"></i>
        </NavLink>
        <NavLink className="social youtube" to="you">
          <i className="fa fa-youtube-play fa-2x" aria-hidden="true"></i>
        </NavLink>
      </div>
      <p>YOUTOPIA SPORTS © 2024 | All Rights Reserved</p>
    </div>
  );
};

export default Footer;
