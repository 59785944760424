import { useNavigate } from "react-router-dom";
import { useContext, useMemo } from "react";
import { MyContext } from "../App";
import TimeDifference from "./timeDiff";
import "./allCSSfiles/homecomp2.css";

const Card = ({ headline, author, createdAt, imgurl, id, index }) => {
  const cardSizes = [1, 2, 3, 4];
  const size = cardSizes[index % cardSizes.length];
  const cardClassName = `card itm${size}`;
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/newsDis/${id}`);
  };

  return (
    <div className={cardClassName} onClick={handleNavigate}>
      <div className="cardimg">
        <img src={imgurl} alt={headline} />
      </div>
      <div className="cardBottom">
        <span className="head">{headline}</span>
        <p className="srcN">
          <TimeDifference data={createdAt} /> <span>{author}</span>
        </p>
      </div>
    </div>
  );
};

const Comp4 = () => {
  const { news } = useContext(MyContext);

  // Memoize items to display
  const itemsToDisplay = useMemo(() => news?.slice(6, 10), [news]);

  return (
    <div className="main-content">
      {itemsToDisplay?.map((item, index) => (
        <Card key={item.id} {...item} index={index} />
      ))}
    </div>
  );
};

export default Comp4;
