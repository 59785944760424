import React, { useContext, useState } from "react";
import { MyContext } from "../App";
import "./allCSSfiles/nextmatch.css";

const FootballTable = () => {
  const { data } = useContext(MyContext);
  const [showFullTable, setShowFullTable] = useState(false); // State to toggle full table view
  const teams = {};

  if (!data) {
    return <div>No data available.</div>;
  }

  const completedMatches = data.filter((match) => match.status === "FT");
  completedMatches.forEach(
    ({ homeName, awayName, homeGoal, awayGoal, homeURL, awayURL }) => {
      if (!teams[homeName]) {
        teams[homeName] = initializeTeam(homeName, homeURL);
      }
      if (!teams[awayName]) {
        teams[awayName] = initializeTeam(awayName, awayURL);
      }
      updateMatchStats(teams[homeName], homeGoal, awayGoal, awayName);
      updateMatchStats(teams[awayName], awayGoal, homeGoal, homeName);
    }
  );

  const sortedTeams = Object.keys(teams)
    .map((team) => ({
      team,
      ...teams[team],
      goalDifference: teams[team].goalsFor - teams[team].goalsAgainst,
    }))
    .sort((a, b) => b.points - a.points || b.goalsFor - a.goalsFor);

  sortedTeams.forEach((team, index) => {
    team.position = index + 1;
  });

  // Decide how many teams to show based on state
  const teamsToShow = showFullTable ? sortedTeams : sortedTeams.slice(0, 6);

  return (
    <div className="standingHome">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Club</th>
            <th>P</th>
            <th>Pts</th>
          </tr>
        </thead>
        <tbody>
          {teamsToShow.map((teamData) => (
            <TeamRow key={teamData.position} teamData={teamData} />
          ))}
        </tbody>
      </table>

      {/* Button to toggle full table */}
      <button
        className="toggle-btn"
        onClick={() => setShowFullTable(!showFullTable)}
      >
        {showFullTable ? "Show less" : "Show more"}
      </button>
    </div>
  );
};

// Initialize team data
const initializeTeam = (name, logoUrl) => ({
  points: 0,
  wins: 0,
  draws: 0,
  losses: 0,
  goalsFor: 0,
  goalsAgainst: 0,
  matchesPlayed: 0,
  recentMatches: [],
  logoUrl,
});

// Update match statistics for a team
const updateMatchStats = (team, goalsFor, goalsAgainst, opponent) => {
  team.matchesPlayed += 1;
  team.goalsFor += Number(goalsFor);
  team.goalsAgainst += Number(goalsAgainst);

  const result =
    goalsFor > goalsAgainst ? "W" : goalsFor < goalsAgainst ? "L" : "D";

  if (result === "W") {
    team.points += 3;
    team.wins += 1;
  } else if (result === "L") {
    team.losses += 1;
  } else {
    team.points += 1;
    team.draws += 1;
  }

  team.recentMatches.unshift({ opponent, result });
  team.recentMatches = team.recentMatches.slice(0, 5);
};

// Memoized team row for performance
const TeamRow = React.memo(({ teamData }) => (
  <tr>
    <td>{teamData.position}</td>
    <td className="club-logo">
      <img src={teamData.logoUrl} alt={`${teamData.team} Logo`} />
      <span>{teamData.team}</span>
    </td>
    <td>{teamData.matchesPlayed}</td>
    <td>{teamData.points}</td>
  </tr>
));

export default FootballTable;
