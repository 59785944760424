import { useContext, useState, useEffect } from "react";
import { MyContext } from "../App";
import Logo from "../logopre/pre.png";
import "./allCSSfiles/result.css";

const Result = () => {
  const { data } = useContext(MyContext);
  const [selectedRound, setSelectedRound] = useState(null);
  const [allRounds, setAllRounds] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    if (data) {
      const uniqueRounds = [...new Set(data.map((item) => item.round))].sort(
        (a, b) => a - b
      );
      setAllRounds(uniqueRounds);
      // Find the current or most recent round based on today's date
      const now = new Date();
      let currentRound = null;
      for (let i = uniqueRounds.length - 1; i >= 0; i--) {
        const roundMatches = data.filter(
          (item) => item.round === uniqueRounds[i]
        );
        const firstMatchDate = new Date(roundMatches[0].date);
        if (firstMatchDate <= now) {
          currentRound = uniqueRounds[i];
          break;
        }
      }
      if (currentRound) {
        setSelectedRound(currentRound);
        const initialIndex = uniqueRounds.findIndex(
          (round) => round === currentRound
        );
        setCarouselIndex(Math.max(0, initialIndex - 1));
      } else {
        setSelectedRound(uniqueRounds[0] || null);
        setCarouselIndex(0);
      }
    }
  }, [data]);

  const filterDataBySelectedRound = () => {
    if (!selectedRound) return [];
    return data.filter((item) => item.round === selectedRound && item.status === "FT");
  };
  const handleRoundClick = (round) => {
    setSelectedRound(round);
  };
  const handleSlideLeft = () => {
    if (carouselIndex > 0) {
      setCarouselIndex(carouselIndex - 1);
    }
  };
  const handleSlideRight = () => {
    if (carouselIndex < allRounds.length - 3) {
      setCarouselIndex(carouselIndex + 1);
    }
  };

  const filteredData = filterDataBySelectedRound();
  const renderRoundCarousel = () => {
    const visibleRounds = allRounds.slice(carouselIndex, carouselIndex + 4);
    return (
      <div className="date-carousel-wrapper">
        <button
          className="carousel-control"
          onClick={handleSlideLeft}
          disabled={carouselIndex === 0}
        >
          &lt;
        </button>
        <div className="date-carousel">
          {visibleRounds.map((round, index) => (
            <button
              key={index}
              className={`carousel-button ${
                selectedRound === round ? "active" : ""
              }`}
              onClick={() => handleRoundClick(round)}
            >
              {`${round}`}
            </button>
          ))}
        </div>
        <button
          className="carousel-control"
          onClick={handleSlideRight}
          disabled={carouselIndex >= allRounds.length - 4}
        >
          &gt;
        </button>
      </div>
    );
  };

  const renderMatches = () => {
    if (filteredData.length === 0) {
      return (
        <div className="r-no-matches-message">
        <p >
          This week match not started yet.
        </p>
        </div>
        
      );
    }

    return filteredData.map((match) => (
      <div className="result-card" key={match.id}>
        
          <div className="result-info">
            <span className="result-span">{match.homeName}</span>
            <img src={match.homeURL} alt={`${match.homeName} logo`} />
            <span className="result-goal">{match.homeGoal}</span>
            <span className="result-goal">{match.awayGoal}</span>
            <img src={match.awayURL} alt={`${match.awayName} logo`} />
            <span className="result-span">{match.awayName}</span>
          </div>
        
        <div className="result-details">
          <span>{match.stadium} stadium</span>
          <span>{match.date}</span>
        </div>
      </div>
    ));
  };
  return (
    <div className="result">
      <div className="prem-logo">
        <img src={Logo} alt="Premier League logo" />
      </div>
      <p className="result-header">ETHIOPIAN PREMIER LEAGUE</p>
      {renderRoundCarousel()}
      {renderMatches()}
    </div>
  );
};

export default Result;
