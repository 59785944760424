import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MyContext } from "../App";
import TimeDifference from "./timeDiff";
import "./allCSSfiles/newsDis.css";
import Weeklymatch from "./weeklyMatch";

const NewsDis = () => {
  const { news } = useContext(MyContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getUpdate = () => {
      try {
        const arr = news || [];
        const match = arr.find((m) => m.id === Number(id));
        setData(match);
      } catch (error) {
        setError("Something went wrong while fetching the news.");
      }
    };
    getUpdate();

    // Scroll to the top of the page whenever `id` changes
    window.scrollTo(0, 0);
  }, [news, id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })} ${date.getFullYear()}`;
  };

  return (
    <>
      <div className="news">
        {error && <div className="error">{error}</div>}
        {data ? (
          <div className="newsdis">
            <img src={data.imgurl} alt="news logo" />
            <div className="newstext">
              <h3>{data.headline}</h3>
              <hr />
              <span>{data.content}</span>
              <p>
                <span>
                  {formatDate(data.createdAt)} | {data.author}
                </span>
              </p>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
        <div className="adv-news">
          <Weeklymatch />
        </div>
      </div>

      <div className="news-related">
        <h2>Related News</h2>
        <div className="new">
          {news &&
            news
              .filter(
                (n) => n.catagory === data?.catagory && n.id !== Number(id)
              )
              .map((relatedNews) => (
                <div
                  className="card"
                  key={relatedNews.id}
                  onClick={() => navigate(`/newsDis/${relatedNews.id}`)}
                >
                  <div className="cardimg">
                    <img src={relatedNews.imgurl} alt="related news logo" />
                  </div>
                  <div className="cardBottom">
                    <span className="head">{relatedNews.headline}</span>
                    <p className="srcN">
                      <span>
                        <TimeDifference data={relatedNews.createdAt} />
                      </span>
                      <span>{relatedNews.author}</span>
                    </p>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default NewsDis;
