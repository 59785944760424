import { useNavigate } from "react-router-dom";
import { useContext, useMemo } from "react";
import { MyContext } from "../App";
import TimeDifference from "./timeDiff";
import "./allCSSfiles/homecom1.css";

const Comp3 = () => {
  const { news } = useContext(MyContext);
  const navigate = useNavigate();

  // Memoize sliced data for performance
  const itemsToDisplay = useMemo(() => news?.slice(5, 6), [news]);

  const handleNavigate = (id) => {
    navigate(`/newsDis/${id}`);
  };

  return (
    <div className="com1">
      {itemsToDisplay?.map((n) => (
        <article
          key={n.id}
          className="card_l"
          onClick={() => handleNavigate(n.id)}
        >
          <div className="l_img">
            <img src={n.imgurl} alt={n.headline} />
          </div>
          <section className="l_text">
            <header className="l_head">{n.headline}</header>
            <p className="l_content">{n.content}</p>
            <p className="l_srcN">
              <TimeDifference data={n.createdAt} />
              <span> {n.author}</span>
            </p>
          </section>
        </article>
      ))}
      <div className="card_adv"></div>
    </div>
  );
};

export default Comp3;
