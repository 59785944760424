// src/components/News.js
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../App";
import TimeDifference from "./timeDiff";
import "./allCSSfiles/news.css";
import Weeklymatch from "./weeklyMatch";

// Reusable component to render each news card
const NewsCard = ({ newsItem, onClick }) => (
  <div className="news-card" key={newsItem.id} onClick={onClick}>
    <div className="news-cardimg">
      <img src={newsItem.imgurl} alt={`${newsItem.headline} logo`} />
    </div>
    <div className="news-cardBottom">
      <span className="news-head">{newsItem.headline}</span>
      <p className="news-srcN">
        <span>
          <TimeDifference data={newsItem.createdAt} />
        </span>
        <span>{newsItem.author}</span>
      </p>
    </div>
  </div>
);

const News = () => {
  const { news } = useContext(MyContext);
  const navigate = useNavigate();

  return (
    <div className="news-container">
      <div className="div-main">
      {news?.length > 0 ? (
            <div className="news-list">
              {news.map((newsItem) => (
                <NewsCard
                  key={newsItem.id}
                  newsItem={newsItem}
                  onClick={() => navigate(`/newsDis/${newsItem.id}`)}
                />
              ))}
          </div>
        ) : (
          <p>No Soccer News Available</p>
        )}
      </div>
        <div className="adv-side">
          <Weeklymatch/>
        </div>
      
    </div>
  );
};

export default News;
