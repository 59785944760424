// LoadingSpinner.js
import React from "react";
import "./allCSSfiles/loading.css";

const LoadingSpinner = () => {
  return (
    <div className="spiner-container">
      <div className="spinner">
        <div className="circle yellow"></div>
        <p>Loading...</p>
        <div className="circle green"></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
