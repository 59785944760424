import React, { useContext } from "react";
import { MyContext } from "../App";
import "./allCSSfiles/table.css";

const FootballTable = () => {
  const { data } = useContext(MyContext);
  const teams = {};

  if (!data) {
    return <div>No data available.</div>;
  }
  const completedMatches = data.filter((match) => match.status === "FT");
  completedMatches.forEach(
    ({ homeName, awayName, homeGoal, awayGoal, homeURL, awayURL }) => {
      if (!teams[homeName]) {
        teams[homeName] = initializeTeam(homeName, homeURL);
      }
      if (!teams[awayName]) {
        teams[awayName] = initializeTeam(awayName, awayURL);
      }
      updateMatchStats(teams[homeName], homeGoal, awayGoal, awayName);
      updateMatchStats(teams[awayName], awayGoal, homeGoal, homeName);
    }
  );

  const sortedTeams = Object.keys(teams)
    .map((team) => ({
      team,
      ...teams[team],
      goalDifference: teams[team].goalsFor - teams[team].goalsAgainst,
    }))
    .sort((a, b) => b.points - a.points || b.goalsFor - a.goalsFor);

  sortedTeams.forEach((team, index) => {
    team.position = index + 1;
  });
  return (
    <div className="standing">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Club</th>
            <th>P</th>
            <th className="mobile-only">W</th>
            <th className="mobile-only">D</th>
            <th className="mobile-only">L</th>
            <th className="mobile-only">GF</th>
            <th className="mobile-only">GA</th>
            <th>GD</th>
            <th>Pts</th>
            <th className="mobile-only">Form</th>
          </tr>
        </thead>
        <tbody>
          {sortedTeams.map((teamData) => (
            <TeamRow key={teamData.position} teamData={teamData} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Initialize team data
const initializeTeam = (name, logoUrl) => ({
  points: 0,
  wins: 0,
  draws: 0,
  losses: 0,
  goalsFor: 0,
  goalsAgainst: 0,
  matchesPlayed: 0,
  recentMatches: [],
  logoUrl,
});

// Update match statistics for a team
const updateMatchStats = (team, goalsFor, goalsAgainst, opponent) => {
  team.matchesPlayed += 1;
  team.goalsFor += Number(goalsFor);
  team.goalsAgainst += Number(goalsAgainst);

  const result =
    goalsFor > goalsAgainst ? "W" : goalsFor < goalsAgainst ? "L" : "D";
  if (result === "W") {
    team.points += 3;
    team.wins += 1;
  } else if (result === "L") {
    team.losses += 1;
  } else {
    team.points += 1;
    team.draws += 1;
  }
  team.recentMatches.unshift({ opponent, result });
  team.recentMatches = team.recentMatches.slice(0, 5);
};

const TeamRow = React.memo(({ teamData }) => (
  <tr>
    <td>{teamData.position}</td>
    <td className="team-logo">
      <img src={teamData.logoUrl} alt={`${teamData.team} Logo`} />
      <span>{teamData.team}</span>
    </td>
    <td>{teamData.matchesPlayed}</td>
    <td className="mobile-only">{teamData.wins}</td>
    <td className="mobile-only">{teamData.draws}</td>
    <td className="mobile-only">{teamData.losses}</td>
    <td className="mobile-only">{teamData.goalsFor}</td>
    <td className="mobile-only">{teamData.goalsAgainst}</td>
    <td>{teamData.goalDifference}</td>
    <td>{teamData.points}</td>
    <td className="mobile-only">
      <ul>
        {teamData.recentMatches.map((match, i) => (
          <li key={i}>{match.result}</li>
        ))}
      </ul>
    </td>
  </tr>
));

export default FootballTable;
