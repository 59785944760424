import { useNavigate } from "react-router-dom";
import { useContext, useMemo } from "react";
import { MyContext } from "../App";
import TimeDifference from "./timeDiff";
import "./allCSSfiles/homecomp2.css";

const Card = ({ headline, author, createdAt, imgurl, id, index }) => {
  const cardSizes = [1, 2, 3, 4];
  const size = cardSizes[index % cardSizes.length];

  const navigate = useNavigate();

  return (
    <div
      className={`card itm${size}`}
      onClick={() => navigate(`/newsDis/${id}`)}
    >
      <div className="cardimg">
        <img src={imgurl} alt={headline} />
      </div>
      <div className="cardBottom">
        <span className="head">{headline}</span>

        <p className="srcN">
          <TimeDifference data={createdAt} /> <span>{author}</span>
        </p>
      </div>
    </div>
  );
};

const Comp2 = () => {
  const { news } = useContext(MyContext);

  // Memoize sliced data to avoid unnecessary recalculations
  const itemsToDisplay = useMemo(() => news?.slice(1, 5), [news]);

  return (
    <div className="main-content">
      {itemsToDisplay?.map((item, index) => (
        <Card key={item.id} {...item} index={index} />
      ))}
    </div>
  );
};

export default Comp2;
