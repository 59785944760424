import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Advert from "./../logopre/youtopiasports.png";
import Navbar from "./navBar";
import Footer from "./footer";
import Livecomp from "./livecomp";
import LoadingSpinner from "./loading"; // Import your loading component

const Initial = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 2-second delay

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <div className="all-container">
      {isLoading ? (
        <LoadingSpinner /> // Show loading spinner while loading
      ) : (
        <>
          <div className="card_adv1">
            <img src={Advert} alt="Premier League logo" />
          </div>
          <Navbar />
          <Livecomp />
          <div className="home-container">
            <Outlet />
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Initial;
