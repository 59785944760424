import React, { useContext } from "react";
import { MyContext } from "../App";

const Livecomp = () => {
  const { data } = useContext(MyContext);
  const arr = data || [];
  const liveMatch = arr.filter((match) => match.status === "Live");
  return (
    <div className="live-match">
      {liveMatch.length > 0
        ? liveMatch.map((match) => (
            <div className="live" key={match.id}>
              <span>{match.homeName}</span>
              <span>{match.homeGoal}</span>
              <button className="live-status">{match.status}</button>
              <span>{match.awayGoal}</span>
              <span>{match.awayName}</span>
            </div>
          ))
        : null}
    </div>
  );
};

export default Livecomp;
